<template>
  <div class="root">
    <template v-if="!isPoster">
      <div class="root-tabs">
        <DuTabs
          v-model:value="current"
          color="secondary"
          @update:value="handleChangeCurrent"
        >
          <DuTab v-for="tab of filter" :name="tab.key" :key="tab.key">
            {{ tab.value }}
          </DuTab>
        </DuTabs>
      </div>
      <div class="root-main">
        <div class="box" v-if="items?.length">
          <Item v-for="item in items" :key="item.id" :auction="item" />
        </div>
        <DuEmpty v-else text="暂无相关拍品" />
      </div>
    </template>

    <template v-else>
      <div class="root-poster">
        <div class="root-title H3">{{ auctions?.fieldName }}</div>
        <template v-for="tab in filter" :key="tab.key">
          <div class="box" v-if="auctions?.[tab.key]?.length">
            <div class="box-title H3">{{ tab.value }}</div>
            <Item
              v-for="item in auctions[tab.key]"
              :key="item.id"
              :auction="item"
            />
          </div>
        </template>
      </div>
      <div v-if="total > 20" class="tips">
        <span style="margin-top: 30px">点击下方链接查看全部拍品</span>
      </div>
    </template>
  </div>
</template>

<script setup>
import { DuTabs, DuTab, DuEmpty } from "dangoui";
import AuctionApi from "@/apis/auction";
import Item from "./components/item.vue";
import { useRoute } from "vue-router";
import { computed, onMounted, ref } from "vue";
import { showToast } from "vant";

const route = useRoute();
const isPoster = ref(route.query?.from === "poster" ? true : false);
const total = ref(0);
const filter = ref([
  { key: "biddingList", value: "竞价中" },
  { key: "pendingList", value: "待领养" },
  { key: "tradedList", value: "已截拍" },
]);
const current = ref("biddingList");
const auctions = ref(null);
const items = computed(() => auctions.value?.[current.value]);
onMounted(async () => {
  getFieldTradeProfile();
});
async function getFieldTradeProfile() {
  try {
    const { data } = await AuctionApi.getFieldTradeProfile({
      fieldId: route.query.fieldId,
    });
    auctions.value = data;
    total.value =
      data.biddingList?.length +
      data.pendingList?.length +
      data.tradedList?.length;
  } catch (error) {
    showToast(error?.message || error);
  }
}
</script>

<style lang="scss">
.root {
  position: relative;
  background: #f7f7f9;
  min-height: 100vh;
  .root-tabs {
    padding: 16rpx 0;
    z-index: 9;
    position: sticky;
    top: 0;
    background: #f7f7f9;
  }
  .root-main {
    padding: 0 20rpx 20rpx;
  }
  .root-poster {
    display: flex;
    flex-direction: column;
    padding: 96rpx 20rpx 0;
  }
  .root-title {
    margin-right: 24rpx;
  }
}
.box {
  padding: 16rpx;
  border-radius: 16rpx;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
}
.box-title {
  padding: 8rpx 0;
  margin-bottom: 32rpx;
}
.tips {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 750rpx;
  height: 208rpx;
  z-index: 99;
  padding: 19px 10px;
  background: linear-gradient(0deg, #fff 31.7%, rgba(255, 255, 255, 0) 99.81%);
  color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: end;
  justify-content: center;
}
</style>
